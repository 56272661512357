import { Text } from '@workos-inc/component-library';
import { ConnectionAttributeMapping } from 'components/attribute-mapping';
import { Card } from 'components/card';
import { Confirm } from 'components/confirm';
import { FileField, TextField } from 'components/fields';
import { Form } from 'components/form';
import { Img } from 'components/image';
import { CopyInput } from 'components/input';
import { MetadataUrlStep } from 'components/sso/steps/saml/metadata-url-step';
import { Article, Title } from 'components/typography';
import { ConnectionStepProps } from 'interfaces/step-props';
import React from 'react';
import { useFeature } from 'utils/feature-flags';

export const VMWareCreateApplication: React.FC<
  Readonly<ConnectionStepProps>
> = ({ appName, onNextStep }) => (
  <Article>
    <Title className="mb-4">Step 1: Create a new SaaS Application</Title>

    <Text>In your Workspace ONE Catalog, click "New".</Text>

    <Img priority height={740} src="/sso/vmware/v1/vmware-1.png" width={1278} />

    <Text>Provide a Name that describes {appName}, then click "Next".</Text>

    <Confirm label="I’ve created a new SaaS Application" onClick={onNextStep} />
  </Article>
);

export const VMWareBasicConfiguration: React.FC<
  Readonly<ConnectionStepProps>
> = ({ connection, onNextStep }) => (
  <Article>
    <Title className="mb-4">Step 2: Basic SAML Configuration</Title>

    <Text>Click the "Configuration" tab from the left sidebar.</Text>

    <CopyInput
      label="Copy this URL/XML"
      value={connection?.saml_sp_metadata_url}
    />

    <Text>Submit the "URL/XML".</Text>

    <Img height={740} src="/sso/vmware/v1/vmware-2.png" width={1278} />

    <Confirm
      label="I’ve completed my Basic SAML Configuration."
      onClick={onNextStep}
    />
  </Article>
);

export const VMWareAdvancedConfiguration: React.FC<
  Readonly<ConnectionStepProps>
> = ({ onNextStep }) => (
  <Article>
    <Title className="mb-4">Step 3: Advanced SAML Configuration</Title>

    <Text>Continue scrolling and expand "Advanced Properties".</Text>

    <Img priority height={740} src="/sso/vmware/v1/vmware-3.png" width={1278} />

    <Text>Enable "Sign Assertion" and "Include Assertion Signature".</Text>

    <Img height={740} src="/sso/vmware/v1/vmware-4.png" width={1278} />

    <Confirm
      label='I’ve enabled "Sign Assertion" and "Include Assertion Signature"'
      onClick={onNextStep}
    />
  </Article>
);

export const VMWareConfigureAttributeMap: React.FC<
  Readonly<ConnectionStepProps>
> = ({ connection, onNextStep }) => (
  <Article>
    <Title className="mb-4">Step 4: Configure Attribute Map</Title>

    <Text>Continue scrolling until "Custom Attribute Mapping".</Text>

    <Img priority height={740} src="/sso/vmware/v1/vmware-5.png" width={1278} />

    <Text>Fill in the following attribute mappings and select "Next".</Text>

    <ConnectionAttributeMapping connectionType={connection?.type} />

    <Text>
      NOTE: Some VMware configurations use user.ExternalId instead of
      user.objectGUID. In this case, you would map the id attribute to
      user.ExternalId.
    </Text>

    <Img height={740} src="/sso/vmware/v1/vmware-6.png" width={1278} />

    <Confirm label="I’ve configured the Attribute Map" onClick={onNextStep} />
  </Article>
);

export const VMWareUploadMetadata: React.FC<Readonly<ConnectionStepProps>> = (
  connectionStepProps,
) => {
  const {
    connection,
    errors,
    onFileInput,
    onNextStep,
    isLoading,
    onInputChange,
    validationErrors,
  } = connectionStepProps;

  const isInputSamlCertificateMetadataUrlEnabled = useFeature(
    'inputSamlCertificateMetadataUrl',
  );

  if (!isInputSamlCertificateMetadataUrlEnabled) {
    return (
      <Article>
        <Title className="mb-4">Step 5: Upload X.509 Certificate</Title>

        <Text>
          After saving your SaaS Application, click "Settings" then "SAML
          Metadata".
        </Text>

        <Img
          priority
          height={740}
          src="/sso/vmware/v1/vmware-7.png"
          width={1278}
        />

        <Text>Download the “Signing Certificate” and upload it below.</Text>

        <Text>
          Close the Settings modal and the newly created SaaS Application. Copy
          the Launch URL and upload it below.
        </Text>

        <Img
          priority
          height={740}
          src="/sso/vmware/v1/vmware-8.png"
          width={1278}
        />

        <Card>
          <Form
            disabled={
              !connection?.saml_idp_url ||
              !connection?.saml_x509_certs ||
              validationErrors?.saml_idp_url?.value === connection?.saml_idp_url
            }
            isLoading={isLoading}
            isUpdate={!!(errors?.saml_idp_url || errors?.saml_x509_certs)}
            onSubmit={onNextStep}
          >
            <FileField
              error={errors?.saml_x509_certs}
              label="Signing Certificate"
              name="saml_x509_certs"
              onUpload={onFileInput}
              value={connection?.saml_x509_certs?.[0]}
            />

            <TextField
              autoFocus={true}
              error={errors?.saml_idp_url || validationErrors?.saml_idp_url}
              label="Launch URL"
              name="saml_idp_url"
              onChange={onInputChange}
              placeholder="https://foocorp.workspaceoneaccess.com:443/SAAS/API/1.0/GET/apps/launch/app/40cc1bbd-8931-46fd-9ff2-105757cf25bd"
              value={connection?.saml_idp_url}
            />
          </Form>
        </Card>
      </Article>
    );
  }

  return (
    <MetadataUrlStep
      {...connectionStepProps}
      idpEntityIdPlaceholder="https://tenant.vmwareidentity.com/SAAS/API/1.0/GET/metadata/idp.xml..."
      idpSsoUrlPlaceholder="https://foocorp.workspaceoneaccess.com..."
      metadataUrlPlaceholder="https://"
      stepTitle="Step 5: Upload Metadata URL"
    >
      <Text>
        The final step for implementing SAML SSO requires sharing your identity
        provider's metadata with the application.
      </Text>

      <Text as="h2" size="large" weight="medium">
        URL Metadata Configuration
      </Text>

      <Text>
        After saving your SaaS Application, click "Settings" and then "SAML
        Metadata". Click on "Copy URL" next to "Identity Provider (IdP)
        metadata" and paste it below.
      </Text>

      <Img
        priority
        height={740}
        src="/sso/vmware/v1/vmware-9.png"
        width={1278}
      />
    </MetadataUrlStep>
  );
};
